import ClassName from "@global/ClassName";
import React, { useEffect, useState } from "react";

interface Props {
  children: React.ReactNode;
}
export default function Note(props: Props) {
  const [id, initialize] = useState(0);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    initialize(count());
  }, []);

  return (
    <span
      className="footnote"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      onTouchEnd={() => setVisible(!visible)}
    >
      [{id}]
      <div
        className={new ClassName("footnote-inner")
          .if(visible, "visible")
          .toString()}
      >
        {props.children}
      </div>
    </span>
  );
}

interface Counter {
  (): number;
  count: number;
}
const count = (() => ++count.count) as Counter;
count.count = 0;
