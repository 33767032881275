import React from "react";

interface Props {
  src: string;
  href: string;
  width: number;
  height: number;
}

export default function Badge(props: Props) {
  return (
    <img
      className="badge"
      alt="Badge"
      src={props.src}
      onClick={() => (window.location.href = props.href)}
      width={props.width}
      height={props.height}
    ></img>
  );
}
