import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "index.css";

import About from "pages/About";
import Resume from "pages/Resume";

const $root = document.getElementById("root") as HTMLDivElement;
ReactDOM.createRoot($root).render(
  <BrowserRouter>
    <main>
      <article className="App">
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="resume" element={<Resume />} />
        </Routes>
      </article>
    </main>
  </BrowserRouter>
);
