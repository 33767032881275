import React, { useEffect } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { DiGithubBadge } from "react-icons/di";

import ProjectTitle from "pages/Resume/ProjectTitle";
import HashTags from "pages/Resume/HashTags";
import Note from "@block/Note";

export default function Resume() {
  useEffect(() => {
    // 임시
    // @ts-expect-error
    import("pages/Resume/style.css");
  }, []);

  return (
    <section>
      <img
        alt="ProfileImage"
        src="https://avatars.githubusercontent.com/u/39524005?v=4"
      />
      <div>
        <div>
          <button
            aria-label="GitHub"
            onClick={() =>
              (window.location.href = "https://github.com/lshqqytiger")
            }
          >
            <DiGithubBadge className="icon-btn" />
          </button>
          <button
            aria-label="op@lsh.sh"
            onClick={() => (window.location.href = "mailto:op@lsh.sh")}
          >
            <AiOutlineMail className="icon-btn" />
          </button>
        </div>
        <p>이승훈(李昇勳)</p>
        <p>Seunghoon Lee</p>
      </div>
      <h1>학력</h1>
      <div>
        <ul>
          <li className="flex">
            구리고등학교
            <p className="term">2019 ~ 2022</p>
          </li>
          <li className="flex">
            숭실대학교 컴퓨터학부 학사과정
            <p className="term">2023 ~ 現</p>
          </li>
        </ul>
      </div>
      <h1>수상 경력</h1>
      <div>
        <ul>
          <li className="flex">
            교내 소프트웨어 공모전
            <p className="term">2020</p>
          </li>
        </ul>
      </div>
      <h1>프로젝트</h1>
      <ProjectTitle github="https://github.com/bfkkutu/KKuTu/tree/ts">
        KKuTu Reatomized
      </ProjectTitle>
      <div>
        <HashTags
          tags={[
            "TypeScript",
            "React",
            "zustand",
            "WebSocket",
            "Node.js",
            "Express",
            "TypeORM",
            "PostgreSQL",
          ]}
        />
        <p>
          오래된 기술 스택으로 개발된 오픈소스 웹 게임 끄투를 모던 웹 기술
          스택을 사용해 처음부터 다시 만드는 프로젝트입니다.
        </p>
        <p>
          React의 전역 상태 관리, 페이지 성능 분석 및 최적화, 관계형
          데이터베이스 설계 등에 있어 많은 고민을 하고 있습니다.
        </p>
      </div>
      <ProjectTitle github="https://github.com/bfkkutu/KKuTu">
        KKuTu
      </ProjectTitle>
      <div>
        <HashTags
          tags={[
            "JavaScript",
            "jQuery",
            "pug",
            "WebSocket",
            "Node.js",
            "Express",
            "PostgreSQL",
          ]}
        />
        <p>
          웹 서버, 웹 소켓, RESTful API, SQL 데이터베이스, CI/CD 등 여러 방면의
          기초 지식을 습득하고 연습하는 데 많은 도움이 된 프로젝트입니다.
        </p>
        <p>
          이전 기술 스택을 기반으로 오랜 기간 유지보수하였으나 현재는 상술한
          Reatomized 프로젝트의 진행으로 중단되었습니다.
        </p>
        <p>
          5년 동안 끄투 프로젝트에 크고 작은 30개의 Pull Request를 생성하였으며
          그 중 13개가 병합되었습니다.
        </p>
        <p>
          오래된 데이터베이스 통신 방식을 버리고{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/JJoriping/KKuTu/pull/958"
          >
            TypeORM으로 리팩토링하는 작업
          </a>
          을 진행했습니다.
        </p>
        <p>
          현재 프리서버{" "}
          <a target="_blank" rel="noreferrer" href="https://bfkkutu.kr">
            BF끄투
          </a>
          를 6년째 운영하고 있습니다.
        </p>
      </div>
      <ProjectTitle github="https://github.com/lshqqytiger/stable-diffusion-webui-amdgpu">
        stable-diffusion-webui-amdgpu
      </ProjectTitle>
      <div>
        <HashTags
          tags={[
            "Python",
            "Gradio",
            "PyTorch",
            "Generative-AI",
            "Diffusion-Models",
            "Stable-diffusion",
            "GPU-Compute",
            "DirectML",
            "ZLUDA",
          ]}
        />
        <p>
          AUTOMATIC1111의{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/AUTOMATIC1111/stable-diffusion-webui"
          >
            stable-diffusion-webui
          </a>{" "}
          프로젝트에{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/microsoft/DirectML"
          >
            DirectML
          </a>
          과{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/vosen/ZLUDA"
          >
            ZLUDA
          </a>{" "}
          지원을 추가하여 NVIDIA GPU 외에도 AMD, Intel 社의 GPU로도 구동할 수
          있도록 하였습니다.
        </p>
        <p>
          Microsoft 社의 <a href="https://github.com/microsoft/Olive">Olive</a>{" "}
          모델 최적화 기술을 사용할 수 있게 하였습니다.
        </p>
        <p>
          이 프로젝트를 계기로 생성형 인공지능과 GPU 제어 분야에 관심을 가지게
          되었습니다.
        </p>
      </div>
      <ProjectTitle github="https://github.com/vladmandic/automatic">
        SD.Next
      </ProjectTitle>
      <div>
        <HashTags
          tags={[
            "Python",
            "PyTorch",
            "ONNX",
            "Generative-AI",
            "Diffusion-Models",
            "Stable-diffusion",
            "DiT",
            "GPU-Compute",
            "ROCm",
            "DirectML",
            "ZLUDA",
          ]}
        />
        <p>소규모 팀으로 개발 중인 생성형 인공지능 도구입니다.</p>
        <p>
          stable-diffuion-webui의 포크로 시작한 프로젝트이지만 Huggingface 社의{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/huggingface/diffusers"
          >
            diffusers
          </a>{" "}
          팀과 협력하며 개발하여 기존 프로젝트와는 완전히 다른 구조를 가지게
          되었습니다.
        </p>
        <p>
          덕분에 Stable diffusion 외에도 각종 DiT
          <Note>
            Hunyuan DiT, Flux 등.{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/facebookresearch/DiT"
            >
              Scalable Diffusion Models with Transformers
            </a>
          </Note>{" "}
          모델을 포함하여 많은 생성형 인공지능 모델을 지원할 수 있게 되었습니다.
        </p>
      </div>
      <ProjectTitle homepage="https://sorrygle.lsh.sh">
        Sorrygle-community
      </ProjectTitle>
      <div>
        <HashTags
          tags={[
            "TypeScript",
            "React",
            "Node.js",
            "Express",
            "TypeORM",
            "MySQL",
          ]}
        />
        <p>
          자신이 작성한{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/jjoriping/sorrygle"
          >
            Sorrygle
          </a>
          <Note>
            <p>문자와 기호로 악보를 작성하는</p>
            <p>오디오 프로그래밍 언어의 일종.</p>
          </Note>
          을 다른 사람과 공유하고 다른 사람이 작성한 Sorrygle을 들어볼 수 있는
          온라인 커뮤니티입니다.
        </p>
        <p>OAuth API를 활용해 로그인 기능을 구현하였습니다.</p>
        <p>댓글과 좋아요 기능을 제공합니다.</p>
      </div>
      <ProjectTitle>discord-sorrygle-bot</ProjectTitle>
      <div>
        <HashTags
          tags={[
            "TypeScript",
            "Node.js",
            "discord-tsx-factory",
            "Sorrygle-community",
          ]}
        />
        <p>
          디스코드<Note>인스턴트 메신저 서비스.</Note>에서 사용할 수 있는
          Sorrygle을 컴파일하고 재생하는 봇입니다.
        </p>
        <p>Sorrygle-community와 묶여 하나의 서비스로 기능합니다.</p>
      </div>
      <ProjectTitle
        github="https://github.com/lshqqytiger/discord-tsx-factory"
        npm="https://www.npmjs.com/package/discord-tsx-factory"
      >
        discord-tsx-factory
      </ProjectTitle>
      <div>
        <HashTags tags={["TypeScript", "Node.js", "npm-published"]} />
        <p>
          디스코드 봇을 TSX 문법으로 작성할 수 있게 해주는 npm 패키지입니다.
        </p>
      </div>
      <ProjectTitle homepage="https://aya.gg">AYA.GG</ProjectTitle>
      <div>
        <HashTags tags={["TypeScript", "Svelte"]} />
        <p>게임 이터널 리턴의 전적 검색 서비스입니다.</p>
        <p>프론트엔드 일부를 개발했습니다.</p>
        <p>
          많은 양의 데이터를 효과적으로 랜더링하는 방법에 대해 고민했습니다.
        </p>
        <p>한글 초성 검색 기능을 구현했습니다.</p>
      </div>
      <ProjectTitle>ai-speaker</ProjectTitle>
      <div>
        <HashTags
          tags={[
            "TypeScript",
            "Node.js",
            "TypeORM",
            "SQLite",
            "Express",
            "Python",
            "ONNX",
            "PyTorch",
          ]}
        />
        <p>
          숭실대학교 창의공학설계 과목에서 팀 프로젝트로 개발한 인공지능
          스피커입니다.
        </p>
        <p>
          라즈베리 파이로 제품을 개발하고 서버에 대용량 언어 모델을 탑재하여
          서비스하였습니다.
        </p>
        <p>
          Google 社의 Speech API를 활용해 사용자의 음성을 텍스트로 변환했습니다.
        </p>
        <p>
          Meta 社의{" "}
          <a target="_blank" rel="noreferrer" href="https://wit.ai">
            wit.ai
          </a>{" "}
          서비스를 활용해 사용자의 음성을 분석했습니다.
        </p>
        <p>
          Open API를 활용한 날씨 기능, SQLite 데이터베이스를 활용한 스케쥴 관리
          기능, 생성형 인공지능과의 대화 기능 등을 제공하였습니다.
        </p>
      </div>
      <ProjectTitle github="https://github.com/lshqqytiger/NEISSchool">
        NEISSchool
      </ProjectTitle>
      <div>
        <HashTags
          tags={[
            "TypeScript",
            "React",
            "Node.js",
            "OpenAPI",
            "Express",
            "TypeORM",
            "MySQL",
          ]}
        />
        <p>
          구리고등학교 2021학년도 과학과제연구로 진행하여 Metaverse On-line
          학술제에 제출한 "학교 정보 및 학교 평가를 제공하는 웹 서비스 개발"
          <Note>보고서, 소논문 형식</Note> 프로젝트입니다.
        </p>
      </div>
      <ProjectTitle github="https://github.com/lshqqytiger/GuriHSLunch">
        GuriHSLunch
      </ProjectTitle>
      <div>
        <HashTags
          tags={["TypeScript", "ReactNative", "EXPO", "Android", "iOS", "Java"]}
        />
        <p>구리고등학교 급식 정보를 제공하는 Android/iOS 애플리케이션입니다.</p>
        <p>
          초기 버전에서는 Java와 안드로이드 내장 WebView를 이용하여 외부
          서버에서 따로 deploy하는 웹 페이지를 띄우는 방식이었지만 이후 React
          Native를 이용하여 직접 NEIS API와 통신하는 모바일 애플리케이션으로
          다시 개발했습니다.
        </p>
      </div>
      <ProjectTitle github="https://github.com/lshqqytiger/KKuTuClient">
        KKuTuClient
      </ProjectTitle>
      <div>
        <HashTags tags={["CSharp", "WebSocket", "WinForm"]} />
        <p>웹 게임 끄투의 데스크톱 클라이언트입니다.</p>
        <p>현재는 개발이 중단되었습니다.</p>
      </div>
      <ProjectTitle homepage="/">개인 홈페이지</ProjectTitle>
      <div>
        <HashTags
          tags={["TypeScript", "React", "React-Router", "GitHub-Pages"]}
        />
        <p>
          GitHub Pages를 통해 gh-pages를 사용해 정적 페이지로 빌드된 React
          애플리케이션을 제공하고 있습니다.
        </p>
      </div>
      <ProjectTitle github="https://github.com/lshqqytiger/python-pdh-wrapper">
        python-pdh-wrapper
      </ProjectTitle>
      <div>
        <HashTags tags={["Python", "C/C++", "Windows"]} />
        <p>
          Windows 라이브러리 pdh.dll의 함수들을 파이썬에서 사용할 수 있도록 하는
          Wrapper입니다.
        </p>
      </div>
      <ProjectTitle
        github="https://github.com/lshqqytiger/binding-promise"
        npm="https://www.npmjs.com/package/binding-promise"
      >
        binding-promise
      </ProjectTitle>
      <div>
        <HashTags tags={["TypeScript", "C++", "Node.js", "npm-published"]} />
        <p>
          Node.js Promise 객체를 위한 네이티브 바인딩 유틸리티 라이브러리입니다.
        </p>
        <p>
          자바스크립트의 [[PromiseState]] internal slot에 동기적으로 접근할 수
          있는 함수들을 제공합니다.
        </p>
      </div>
      <ProjectTitle
        github="https://github.com/lshqqytiger/wasm-fs"
        npm="https://www.npmjs.com/package/wasm-fs"
      >
        wasm-fs
      </ProjectTitle>
      <div>
        <HashTags
          tags={["TypeScript", "WebAssembly", "Node.js", "npm-published"]}
        />
        <p>
          웹 어셈블리에 내장된 파일 시스템을 읽어 Node.js에서 사용할 수 있도록
          하는 모듈입니다.
        </p>
      </div>
      <ProjectTitle github="https://github.com/numgle/numgle-ts">
        numgle-ts
      </ProjectTitle>
      <div>
        <HashTags tags={["TypeScript", "Node.js"]} />
        <p>넘어진 글자 프로젝트에 참여해 개발한 웹 프로젝트입니다.</p>
      </div>
      <ProjectTitle
        github="https://github.com/lshqqytiger/passport-daldalso"
        npm="https://www.npmjs.com/package/passport-daldalso"
      >
        passport-daldalso
      </ProjectTitle>
      <div>
        <HashTags
          tags={[
            "TypeScript",
            "Node.js",
            "passport",
            "OAuth2",
            "npm-published",
          ]}
        />
        <p>
          블로그 서비스 <a href="https://daldal.so">달달소</a>의 OAuth API를
          이용하기 위한 Passport Strategy입니다.
        </p>
      </div>
      <ProjectTitle>gurihs-dbb</ProjectTitle>
      <div>
        <HashTags tags={["TypeScript", "React", "Node.js"]} />
        <p>고등학교 로비의 디지털 게시판과 그 관리 도구를 개발했습니다.</p>
        <p>서버를 AWS EC2 인스턴스에 올려 서비스하였습니다.</p>
      </div>
      <ProjectTitle github="https://github.com/lshqqytiger/Sorrygle-cpp">
        Sorrygle-cpp
      </ProjectTitle>
      <div>
        <HashTags tags={["C++", "Parser"]} />
        <p>C++로 제작한 Sorrygle의 LL1 파서입니다.</p>
      </div>
      <ProjectTitle github="https://github.com/lshqqytiger/GuriHSLoL">
        GuriHSLoL
      </ProjectTitle>
      <div>
        <HashTags tags={["TypeScript", "Node.js", "TypeORM", "MySQL"]} />
        <p>
          구리고등학교 학생회에서 진행한 리그 오브 레전드 E-스포츠 대회에 이용한
          디스코드 봇입니다.
        </p>
      </div>
    </section>
  );
}
