import React from "react";

interface Props {
  title: string;
  description: string;
  href: string;
}

export default function ProjectItem(props: Props) {
  return (
    <div
      className="project-item"
      onClick={() => (window.location.href = props.href)}
    >
      <h2>{props.title}</h2>
      {props.description}
    </div>
  );
}
