import React from "react";
import { DiGithubBadge, DiNpm } from "react-icons/di";
import { FaHome } from "react-icons/fa";

interface Props {
  homepage?: string;
  github?: string;
  npm?: string;
  children: React.ReactNode;
}
export default function ProjectTitle(props: Props) {
  return (
    <div className="project-title">
      <h3>{props.children}</h3>
      {props.homepage === undefined ? null : (
        <button
          className="button-icon"
          onClick={() => window.open(props.homepage)}
        >
          <FaHome />
        </button>
      )}
      {props.github === undefined ? null : (
        <button
          className="button-icon"
          onClick={() => window.open(props.github)}
        >
          <DiGithubBadge />
        </button>
      )}
      {props.npm === undefined ? null : (
        <button className="button-icon" onClick={() => window.open(props.npm)}>
          <DiNpm />
        </button>
      )}
    </div>
  );
}
