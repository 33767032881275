import React from "react";

interface Props {
  tags: string[];
}
export default function HashTags(props: Props) {
  return (
    <ul className="hash-tags">
      {props.tags.map((tag, index) => (
        <li key={index}>#{tag}</li>
      ))}
    </ul>
  );
}
