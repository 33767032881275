import React, { useEffect } from "react";
import { AiOutlineMail } from "react-icons/ai";
import {
  DiNodejs,
  DiReact,
  DiMysql,
  DiPostgresql,
  DiGit,
  DiHtml5,
  DiJqueryLogo,
  DiNpm,
  DiGithubBadge,
} from "react-icons/di";
import {
  SiCplusplus,
  SiJavascript,
  SiTypescript,
  SiWebassembly,
  SiPython,
  SiPytorch,
  SiDocker,
  SiRust,
} from "react-icons/si";

import Badge from "@block/Badge";
import ProjectItem from "pages/About/ProjectItem";

export default function About() {
  useEffect(() => {
    // 임시
    // @ts-expect-error
    import("pages/About/style.css");
  }, []);

  return (
    <div className="flex-center flex-column">
      <div>
        <h1>이승훈</h1>
        <h3>Seunghoon Lee</h3>
        <div className="flex-center">
          <button
            aria-label="GitHub"
            onClick={() =>
              (window.location.href = "https://github.com/lshqqytiger")
            }
          >
            <DiGithubBadge className="icon-btn" />
          </button>
          <button
            aria-label="op@lsh.sh"
            onClick={() => (window.location.href = "mailto:op@lsh.sh")}
          >
            <AiOutlineMail className="icon-btn" />
          </button>
        </div>
      </div>
      <h1>My Projects</h1>
      <div>
        {window.innerWidth > 900 ? (
          <div className="project-wrapper">
            <ProjectItem
              title="discord-tsx-factory"
              description="Write Discord.js component in TSX without React."
              href="https://github.com/lshqqytiger/discord-tsx-factory"
            />
            <ProjectItem
              title="Sorrygle Community"
              description="Upload and share your sorrygle."
              href="https://sorrygle.lsh.sh"
            />
            <ProjectItem
              title="stable-diffusion-webui-amdgpu"
              description="A fork of Stable diffusion WebUI for AMDGPUs."
              href="https://github.com/lshqqytiger/stable-diffusion-webui-directml"
            />
            <ProjectItem
              title="passport-daldalso"
              description="Daldalso authentication strategy for Passport. (OAuth 2.0)"
              href="https://github.com/lshqqytiger/passport-daldalso"
            />
            <ProjectItem
              title="KKuTu Reatomized"
              description="Refactored KKuTu with React & TypeScript."
              href="https://github.com/bfkkutu/KKuTu"
            />
            <ProjectItem
              title="integrated-services-manager"
              description="An Electron app to manage multiple Node.js apps."
              href="https://github.com/lshqqytiger/integrated-services-manager"
            />
          </div>
        ) : null}
        <a href="https://github.com/lshqqytiger?tab=repositories">
          {window.innerWidth > 900 ? "More..." : "Repositories"}
        </a>
      </div>
      {window.innerWidth > 900 ? (
        <>
          <h1>Other Projects</h1>
          <div className="project-wrapper">
            <ProjectItem
              title="SD.Next"
              description="Advanced Implementation of Stable Diffusion and other Diffusion-based generative image models."
              href="https://github.com/vladmandic/automatic"
            />
            <ProjectItem title="AYA.GG" description="" href="https://aya.gg" />
          </div>
        </>
      ) : null}
      <div className="skills">
        <h1>Most Used Languages</h1>
        <div className="icon-wrapper">
          <SiJavascript className="si-icon" />
          <SiTypescript className="si-icon" />
          <SiCplusplus className="si-icon" />
          <SiRust className="si-icon" />
          <SiPython className="si-icon" />
        </div>
        <h1>I'm currently learning</h1>
        <div className="icon-wrapper">
          <SiCplusplus className="si-icon" />
          <SiRust className="si-icon" />
        </div>
        <h1>My Skills</h1>
        <div className="icon-wrapper">
          <DiNodejs className="di-icon" />
          <DiNpm className="di-icon" />
          <DiReact className="di-icon" />
          <SiWebassembly className="si-icon" />
          <DiGit className="di-icon" />
          <DiHtml5 className="di-icon" />
          <DiJqueryLogo className="di-icon" />
          <DiMysql className="di-icon" />
          <DiPostgresql className="di-icon" />
          <SiPytorch className="si-icon" />
          <SiDocker className="si-icon" />
        </div>
      </div>
      <h1>Problem Solving</h1>
      <div>
        <Badge
          src="https://mazassumnida.wtf/api/v2/generate_badge?boj=lshqqytiger"
          href="https://solved.ac/profile/lshqqytiger"
          width={350}
          height={170}
        />
      </div>
    </div>
  );
}
